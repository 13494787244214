<template>
  <div>
    <NoticeBan :title="title"></NoticeBan>
    
    <NewNotice />
  </div>
</template>

<script>
import NoticeBan from '@/components/notice/NoticeBan';
 
import NewNotice from '@/components/notice/new-notice'
export default {
  components: {
    NoticeBan,
    NewNotice
  },
  data() {
    return {
      title: this.$t('header.notice')
    }
  }
}
</script>
<style scoped></style>