<template>
  <div>
    <NoticeBan :title="title"></NoticeBan>
    <NoticeDetail />
  </div>
</template>

<script>
 
import NoticeDetail from '@/components/notice/notice-detail'
import NoticeBan from '@/components/notice/NoticeBan';
export default {
  components: {
    NoticeBan,
    NoticeDetail
  },
  data() {
    return {
      title: this.$t('header.notice')
    }
  }
}
</script>
<style scoped></style>