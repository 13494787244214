<template>
    <div class="ban">
        {{ this.$t('header.notice') }}
    </div>
</template>
<script>
export default {
    props: {
        title: String,
    }
}
</script>
<style scoped>
.ban {
    width: 100%;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url(../../assets/img/notice/ban.png) no-repeat center center;
    background-size: 100% 100%;
    font-family: MicrosoftYaHeiUI-Bold;
    font-size: 50px;
    font-weight: bold;
    color: #ffffff;
}

@media screen and (max-width: 992px) {
    .ban {
        height: 220px;
        font-size: 25px;
        margin-top: 64px;
    }
}
</style>