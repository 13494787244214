<template>
  <div class="warpper">
    <div class="notice-content">
      <p class="title">{{ articleData.post_title }}</p>
      <p class="time">{{ articleData.post_date }}</p>
      <div class="content" v-html="articleData.post_content"></div>
    </div>
  </div>
</template>

<script>
import { GbpService } from '@/service/gbp'
const gbpService = new GbpService()
export default {
  components: {},
  props: {},
  data() {
    return {
      articleId: '',
      articleData: {}
    }
  },
  methods: {
    async getArticleDetail() {
      await gbpService.getArticleDetail(this.articleId, this.$i18n.locale).then(res => {
        console.log(res);
        this.articleData = res.data
      })
    }
  },
  mounted() {
    this.getArticleDetail()
  },
  created() {
    if (Object.keys(this.$route.query).length > 0) {
      this.articleId = this.$route.query.id
    }
  }
}
</script>
<style scoped lang="scss">
.warpper {
  .notice-content {
    width: 1150px;
    margin: 0 auto;
    padding-top: 48px;

    .title {
      color: #fff;
      text-align: center;
      font-size: 28px;
      padding-bottom: 10px;
    }

    .time {
      color: #ddd;
      text-align: center;
      margin-top: 8px;
    }

    .content {
      width: 960px;
      color: #fff;
      padding: 20px 10px;
      overflow: hidden;
      line-height: 24px;
      border-bottom: 1px solid #e0e1dc;
      border-top: 1px solid #e0e1dc;
      text-align: left;
      margin: 20px auto;
      font-size: 14px;

      /deep/ table {
        width: 100%;
        margin: 16px auto;

        td {
          border-collapse: collapse;
          border: 1px solid #ccc;
          padding: 15px 0;
          text-align: center;
        }

        td:first-of-type,
        td:nth-of-type(3),
        td:nth-of-type(5) {
          background: transparent;
        }
      }
    }
  }

  .more-click {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0 60px;

    &>div {
      display: inline-block;
      font-size: 14px;
      font-weight: 400;
      color: rgba($color: #fff, $alpha: 0.6);
      line-height: 20px;
      cursor: pointer;
    }
  }
}

@media screen and (min-width: 1200px) {}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .warpper {
    .notice-content {
      width: 95vw;

      .list {
        width: calc((100% - 60px) / 4);
        flex-basis: calc((100% - 60px) / 4);
        margin-right: 20px;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .warpper {
    .notice-content {
      width: 95vw;

      .title {
        font-size: 20px;
      }

      .list {
        width: 100%;
        flex-basis: 100%;
        margin-right: 0;
      }

      .content {
        width: 100%;
        font-size: 12px;
        line-height: 20px;
      }
    }
  }
}
</style>
